<template>
  <div class="container-fluid mt-3">
    <div class="d-flex justify-content-between flex-wrap">
      <div class="d-flex flex-wrap mb-3 mb-sm-0 justify-content-between flex-grow-1">

        <Breadcrumb current_page="جميع المقالات"/>
        <el-button class="ms-2" type="success" @click="$router.push('/add-news')">
          إضافة مقال جديد
        </el-button>
      </div>
      <collapse-filter>
        <form @submit.prevent="handleSearch" class="d-flex gap-3 flex-wrap filter-form">
          <div class="form-group">
            <label>بحث بحسب ال id</label>
            <input type="text" v-model="id" class="form-control"/>
          </div>

          <div class="form-group">
            <label>العنوان</label>
            <input type="text" v-model="title" class="form-control"/>
          </div>

          <div class="form-group">
            <label class="d-block">التصنيف</label>
            <el-select v-model="category_id" filterable placeholder="Select">
              <el-option-group
                  v-for="item in categories"
                  :key="item.id"
                  :label="item.name"
              >
                <el-option
                    v-for="subCategory in item.children"
                    :key="subCategory.id"
                    :label="subCategory.name"
                    :value="subCategory.id"
                >
                </el-option>
              </el-option-group>
            </el-select>
          </div>

          <div class="form-group">
            <label>التاريخ</label>
            <v-date-picker v-model="dateRange" is-range>
              <template v-slot="{ inputValue, inputEvents }">
                <div class="flex justify-center items-center">
                  <input
                      :value="inputValue.start ? `${inputValue.start} - ${inputValue.end}` : ''"
                      v-on="inputEvents.start"
                      class="border px-2 py-1 w-32 rounded focus:outline-none focus:border-indigo-300"
                  />
                </div>
              </template>
            </v-date-picker>
          </div>

          <div class="form-group align-self-end ">
            <button class="btn btn-search px-3" type="submit" @click="handleSearch" :disabled="loading">
              بحث
            </button>
            <button class="btn btn-warning btn-reset px-2 mx-3" type="reset" @click="resetSearch">
              reset
            </button>
          </div>
        </form>
      </collapse-filter>
    </div>


    <div>
      <el-card class="py-3 mt-2 table-responsive overflow-auto">

        <el-table id="top-table" :data="articles_list" class="mb-4" stripe style="width: 100%" max-height="850"
                  v-loading="loading">

          <el-table-column prop="id" class-name="px-3" label="الترتيب" width="100"/>

          <el-table-column prop="title" width="480" class-name="text-end" label="عنوان الخبر">
            <template #default="scope">
              <a :href="scope.row.url" :title="scope.row.title" class="news-link" target="_blank">
                {{ scope.row.title }}
              </a>
            </template>
          </el-table-column>

          <el-table-column class-name="text-end" width="200" label="الكاتب">
            <template #default="scope">
              <span>{{ scope.row.author?.name }}</span>
            </template>
          </el-table-column>

          <el-table-column prop="no_of_views" width="120" label="المشاهدات"/>

          <el-table-column label="التاريخ" width="160">
            <template #default="scope">
              <div dir="ltr" style="display: flex;justify-content:end;  align-items: center">
                <span style="margin-left: 10px">{{ publishDate(scope.row.date_to_publish) }}</span>
              </div>
            </template>
          </el-table-column>

          <el-table-column label="الأدوات" width="120" class-name="control-btns">
            <template #default="scope">
              <el-tooltip class="item" effect="dark" content="تعديل المقال" placement="top">
                <el-button @click="editArticle(scope.row.id)" class="py-1 px-2 btn btn-outline-primary">
                  <i class="far fa-edit"/>
                </el-button>
              </el-tooltip>

              <el-popconfirm
                  hide-icon
                  confirmButtonType="danger"
                  cancelButtonType="default"
                  confirmButtonText="نعم"
                  cancelButtonText="لا"
                  iconColor="red"
                  @confirm="confirmDeactivate(scope.row.id)"
                  title="هل أنت متأكد أنك تريد تعطيل هذا المقال؟">
                <template #reference>
                  <el-button :title="`${scope.row.is_disabled ? 'تفعيل المقال' : 'تعطيل المقال'}`"
                             class="py-1 px-2 btn "
                             :class="`${scope.row.is_disabled ? 'btn-outline-secondary' : 'btn-outline-warning'}`">
                    <i class="las la-ban" style="font-size: 1.25em"/>
                  </el-button>
                </template>
              </el-popconfirm>

              <el-popconfirm
                  hide-icon
                  confirmButtonType="danger"
                  cancelButtonType="default"
                  confirmButtonText="نعم"
                  cancelButtonText="لا"
                  iconColor="red"
                  @confirm="confirmDelete(scope.row.id)"
                  title="هل أنت متأكد أنك تريد حذف هذا المقال؟">
                <template #reference>
                  <el-button title="حذف المقال" class="py-1 px-2 btn btn-outline-danger">
                    <i class="far fa-trash-alt"/>
                  </el-button>
                </template>
              </el-popconfirm>

            </template>
          </el-table-column>
        </el-table>
        <pagination v-if="articles_list.length" v-model="paginationSetup.page" :records="paginationSetup.total"
                    :per-page="paginationSetup.per_page" :options="paginationOptions" @paginate="fetchArticles"/>

      </el-card>

    </div>

  </div>

</template>


<script setup>
import {computed, onMounted, reactive, ref} from "vue";
import {useRouter} from 'vue-router';
import {useStore} from 'vuex';
import {useToast} from "vue-toastification";

import Loader from "@/components/Loader";
import Breadcrumb from "@/components/Breadcrumb";

import {deactivateArticle, deleteArticle, filterArticles, getAllArticles} from "@/services/articles";
import {dateFormatter} from "@/utils/formatDate";

import CollapseFilter from "@/components/CollapseFilter";

const store  = useStore();
const router = useRouter();
const toast  = useToast();

const loading       = ref(true)
const articles_list = ref([])
const id            = ref('')
const title         = ref('')
const category_id   = ref('')

const date_from       = ref('')
const date_to         = ref('')
const dateRange       = computed(
    {
      get()
      {
        return {
          start: new Date(),
          end  : new Date()
        }
      },
      set(newValue)
      {
        date_from.value = newValue.start
        date_to.value   = newValue.end
      }
    }
)
const paginationSetup = reactive(
    {
      page    : null,
      total   : null,
      per_page: null
    }
)

const paginationOptions = {
  chunk: 5,
  theme: "bootstrap4",
  hideCount:true
}
const articleTable      = ref(null);

const categories    = computed(() => store.getters.categories)
const publishDate   = computed(() => date => dateFormatter(date))
const fetchArticles = async () => {
  loading.value = true
  try
  {
    const result             = await getAllArticles(paginationSetup.page || 1)
    loading.value            = false
    paginationSetup.page     = result.data.current_page;
    paginationSetup.total    = result.data.total;
    paginationSetup.per_page = result.data.per_page;
    articles_list.value      = [...result.data.data];
    let topOfElement         = document.querySelector('#top-table').offsetTop;
    window.scroll({top: topOfElement, behavior: "smooth"});
  } catch (e)
  {
    console.log(e)
  }
}

onMounted(() => {
  fetchArticles()
  store.dispatch('fetchCategories')
})

const resetSearch = () => {
  category_id.value = '';
  id.value          = '';
  title.value       = '';
}

const handleSearch = () => {
  let searchObj = {
    category_id: category_id.value,
    id         : id.value,
    title      : title.value,
    from_date  : date_from.value.toLocaleString("en-US"),
    to_date    : date_to.value.toLocaleString("en-US")
  }

  loading.value = true

  filterArticles(searchObj)
      .then(result => {
        loading.value            = false
        articles_list.value      = result.data.data
        paginationSetup.page     = result.data.current_page;
        paginationSetup.total    = result.data.total;
        paginationSetup.per_page = result.data.per_page;
      }).catch(() => loading.value = false)
}

const editArticle       = (id) => router.push(`/edit-article/${id}`)
const confirmDeactivate = id => (
    deactivateArticle(id)
        .then(() => {
          let articleToDisable = articles_list.value.find(article => article?.id === id)
          articleToDisable.is_disabled = !articleToDisable.is_disabled
          toast.success("تم التعطيل بنجاح");
        })
        .catch(() => {
          toast.error("حدث خطأ, الرجاء المحاولة مرة أخرى");
        })
)

const confirmDelete = id => (
    deleteArticle(id)
        .then(() => {
          let articleToDelete = articles_list.value.findIndex(article => article?.id === id)
          articles_list.value.splice(articleToDelete, 1);
          toast.success("تم الحذف بنجاح");
        })
        .catch(() => {
          toast.error("حدث خطأ, الرجاء المحاولة مرة أخرى");
        })
)
</script>

<style lang="scss">

.filter-form {
  input,
  select {
    min-width: 250px;
    height: 40px;
  }
}

.el-table .cell {
  word-break: unset;
  text-overflow: unset;
}


.el-popconfirm__action {
  display: flex;
  gap: .5rem;
  flex-direction: row-reverse;
  justify-content: center;

  .el-button + .el-button {
    margin-left: 0;
  }

  button {
    min-width: 50px;
  }
}
</style>
