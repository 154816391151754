import request from "@/utils/request";

export function getAllArticles(page=1){
  return request(
    {
      url: `articles?page=${page}`,
      method: "get"
    }
  )
}

export function filterArticles(data){
  return request(
    {
      url: 'articles/search',
      method: "post",
      data
    }
  )

}


export function deactivateArticle(article_id) {
  return request(
    {
      url: `articles/status/${article_id}`,
      method: "get"
    }
  )
}

export function deleteArticle(article_id){
  return request(
    {
      url: `articles/${article_id}`,
      method: "delete"
    }
  )
}

export function getSingleArticle(article_id) {
  return request(
    {
      url: `articles/${article_id}`,
      method: "get"
    }
  )
}

export function getAuthors(key) {
  return request(
    {
      url: `search-authors/${key}`,
      method: "get"
    }
  )
}

export function saveAsDraft(data) {
  return request(
    {
      url: "articles/draft",
      method: "post",
      data
    }
  )
}

export function saveArticle(data){
  return request(
    {
      url:'articles',
      method: "post",
      data
    }
  )
}

export function updateArticle(article_id,data) {
  return request(
    {
      url: `articles/${article_id}`,
      method: "put",
      data
    }
  )
}
